import { Row, Col } from 'react-bootstrap'
import { FaGithub, FaTwitter, FaDiscord, FaHome } from 'react-icons/fa'

function Footer() {
  return (
    <footer className="mt-5 p-4" style={{ backgroundColor: '#001e3b' }}>
      <Row>
        <Col sm>
          &copy; 2021 <a href="https://artofcoding.nl" target="_blank" rel="noopener noreferrer">Art of Coding</a>. All rights reserved. Powered by <a href="https://github.com/Art-of-Coding/bloggit" target="_blank" rel="noopener norefer">BlogGit</a>.
        </Col>
        <Col sm className="text-end" style={{ fontSize: '18px' }}>
          <a href="https://artofcoding.nl" rel="noreferrer noopener" target="_blank" title="Go to our homepage"><FaHome /></a> <a href="https://github.com/Art-of-Coding" rel="noreferrer noopener" target="_blank" title="Visit us on GitHub"><FaGithub /></a> <a href="https://discord.gg/YeXvZK7r2s" target="_blank" rel="noreferrer nofollow" title="Join us on Discord"><FaDiscord /></a> <a href="https://twitter.com/art-of-coding" target="_blank" rel="noreferrer nofollow" title="Follow us on Twitter"><FaTwitter /></a>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer