import { useState, useEffect } from 'react'

type Meta = {
  slug: string,
  title: string,
  description: string,
  author: {
    name: string,
    email: string,
  },
  tags: string[],
  published: string,
  content: string,
}[]

export function useMeta(repo: string) {
  const [meta, setMeta] = useState<Meta | null>(null)

  useEffect(() => {
    const url = `https://api.github.com/repos/${repo}/contents/meta.json`
    fetch(url)
      .then(response => response.json())
      .then(data => setMeta(JSON.parse(Buffer.from(data.content, 'base64').toString('utf-8'))))
      .catch(err => console.error(err))
  }, [repo])

  return meta
}

export function usePost(repo: string, slug: string) {
  const [post, setPost] = useState<string | null>(null)

  useEffect(() => {
    const url = `https://api.github.com/repos/${repo}/contents/posts/${slug}.md`
    fetch(url)
      .then(response => response.json())
      .then(data => setPost(Buffer.from(data.content, 'base64').toString('utf-8')))
      .catch(err => console.error(err))
  }, [repo, slug])

  return post
}