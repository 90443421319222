import { Container, Badge, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import TimeAgo from 'react-timeago'
import Skeleton from 'react-loading-skeleton'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Footer from '../components/Footer'
import { useMeta } from '../hooks/useMeta'
import 'react-loading-skeleton/dist/skeleton.css'

function List() {
  const meta = useMeta('Art-of-Coding/blog')

  return (
    <>
      <Container style={{ maxWidth: '900px' }}>
        <h1 className="mt-4">Blog</h1>
        <hr />

        {meta?.length ? meta.map(post => {
          return (
            <div className="mt-4 mb-4 pb-4" key={post.slug}>
              <h6>Post</h6>
              <h3 className="display-6" style={{ fontSize: '30px' }}><Link to={`/posts/${post.slug}`} style={{ textDecoration: 'none' }}>{post.title}</Link></h3>
              {/* <Link to="/posts/slug"><Image src="https://via.placeholder.com/900x250" width="100%" className="mt-2 mb-4" title={post.title} /></Link> */}
              <p>{<ReactMarkdown children={post.description} remarkPlugins={[remarkGfm]} />}</p>
              <span className="text-muted"><TimeAgo date={post.published} /> &bull; By {post.author.name} {post.tags.map(tag => <Badge className="m-2 mt-0">{tag}</Badge>)}</span>
            </div>
          )
        }) : (
          <Skeleton count={5} />
        )}
      </Container>

      <Footer />
    </>
  )
}

export default List