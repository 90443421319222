import { Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import List from './pages/List'
import Post from './pages/Post'

function App() {
  return (
    <>
      <Header backgroundImage="/bg.avif" />

      <Routes>
        <Route path="/posts/:slug" element={<Post />} />
        <Route path="/" element={<List />} />
      </Routes>
    </>
  )
}

export default App
