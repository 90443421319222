import type { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaHome, FaGithub, FaDiscord } from 'react-icons/fa'

type HeaderProps = {
  backgroundColor?: string,
  backgroundImage?: string,
}

const Header: FunctionComponent<HeaderProps> = ({ backgroundColor = '#001011', backgroundImage = 'bg.webp' }) => {
  return (
    <div style={{
      minHeight: '50vh',
      backgroundColor,
      backgroundImage: `url("${backgroundImage}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingTop: '10vh',
    }} className="text-center header">
      <h1 className="display-1" style={{ textShadow: '2px 2px #011421', fontWeight: 'bold' }}>Art of Coding</h1>
      <Container style={{ maxWidth: '900px' }}>
        <hr />
      </Container>
      <h2 className="display-6" style={{ textShadow: '1px 1px #011421' }}>It's the art of creation, the art of coding</h2>
      <p style={{ fontSize: '22px' }}>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="link-home">Back to posts</Tooltip>}>
          <Link to="/"><FaHome className="m-2" /></Link>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="link-gh">Visit us on GitHub</Tooltip>}>
          <a href="https://github.com/Art-of-Coding" rel="noreferrer nofollow" target="_blank"><FaGithub className="m-2" /></a>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="link-discord">Join us on Discord</Tooltip>}>
          <a href="https://discord.gg/YeXvZK7r2s" target="_blank" rel="noreferrer nofollow"><FaDiscord className="m-2" /></a>
        </OverlayTrigger>
        {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="link-twitter">Follow us on Twitter</Tooltip>}>
          <a href="https://twitter.com/Art-of-Coding" target="_blank" rel="noreferrer nofollow"><FaTwitter className="m-2" /></a>
        </OverlayTrigger> */}
      </p>
    </div>
  )
}

export default Header