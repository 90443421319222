import { Badge, Col, Container, Figure, Row } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import TimeAgo from 'react-timeago'
import Skeleton from 'react-loading-skeleton'
import { useMeta, usePost } from '../hooks/useMeta'
import Footer from '../components/Footer'
import { FaGithub } from 'react-icons/fa'
import 'react-loading-skeleton/dist/skeleton.css'

function Post() {
  const { slug } = useParams() as { slug: string }
  const meta = useMeta('Art-of-Coding/blog')
  const post = usePost('Art-of-Coding/blog', slug)

  const postMeta = meta?.find(m => m.slug === slug)

  return (
    <>
      <Container className="mt-5" style={{ maxWidth: '1100px' }}>
        <Row>
          <Col sm={2} style={{ marginTop: '60px' }} className="text-center">
            <p>Published:<br />
              {!postMeta ? <Skeleton /> : <TimeAgo date={postMeta.published} />}</p>
            <p>Author:</p>
            <Figure className="text-center">
              {postMeta ? (
                <Figure.Image src={`https://www.gravatar.com/avatar/${Buffer.from(postMeta.author.email, 'utf-8').toString('base64')}`} />
              ) : null}
              <Figure.Caption>{!postMeta ? <Skeleton /> : postMeta.author.name}</Figure.Caption>
            </Figure><br />
            {!postMeta ? <Skeleton /> : postMeta.tags.map(tag => <Badge className="m-2" key={tag}>{tag}</Badge>)}
          </Col>
          <Col sm>
            <h1 className="display-6">{!postMeta ? <Skeleton /> : postMeta.title}</h1>
            <hr />
            {!post ? <Skeleton count={12} /> : (
              <ReactMarkdown children={post} remarkPlugins={[remarkGfm]} components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '')
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={materialDark}
                      language={match[1]}
                      PreTag="div"
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  )
                }
              }} />
            )}
            <div className="mt-4 text-end">
              <Link to="/" className="gh-link" style={{ fontSize: '12px' }}>Back to posts</Link> - <a href={`https://github.com/Art-of-Coding/blog/blob/main/posts/${slug}.md`} target="_blank" rel="noopener noreferrer" className="gh-link" style={{ fontSize: '12px' }}>View this post on <FaGithub /> GitHub</a>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  )
}

export default Post